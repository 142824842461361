(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/bottom-sheet-intersect/assets/javascripts/bottom-sheet-intersersect.js') >= 0) return;  svs.modules.push('/components/lb-ui/bottom-sheet-intersect/assets/javascripts/bottom-sheet-intersersect.js');
"use strict";


const {
  useEffect,
  useRef,
  useState
} = React;
const INTERSECTION_SENSITIVITY = 0.1;

const BottomSheetIntersersect = _ref => {
  let {
    children,
    intersectId
  } = _ref;
  if (!intersectId) {
    throw new Error('intersectId is mandatory');
  }
  const observer = useRef(null);
  const lastScrollTopPosition = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => () => {
    if (observer.current) {
      observer.current.disconnect();
      if (document.querySelector('body')) {
        document.querySelector('body').style.overflow = 'auto';
      }
    }
  }, []);
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      if (document.querySelector('body')) {
        document.querySelector('body').style.overflow = 'hidden';
      }
      observer.current = new IntersectionObserver(entries => {
        entries.forEach(_ref2 => {
          let {
            boundingClientRect,
            intersectionRatio
          } = _ref2;
          const scrollingDown = lastScrollTopPosition.current > boundingClientRect.top;
          if (lastScrollTopPosition.current === null || scrollingDown) {
            const isIntersectingValue = intersectionRatio > INTERSECTION_SENSITIVITY;
            if (isIntersectingValue !== isIntersecting) {
              setIsIntersecting(isIntersectingValue);
            }
          } else {
            setIsIntersecting(false);
          }
          lastScrollTopPosition.current = boundingClientRect.top;
        });
      }, {
        threshold: [0, 0.2]
      });
      observer.current.observe(document.getElementById(intersectId));
    }
  }, []);
  return React.createElement(svs.ui.ReactBottomSheet.BottomSheet, {
    behavior: "standard",
    className: "qa-bottom-sheet-intersect-".concat(isIntersecting ? 'hidden' : 'visible'),
    customer: "sport",
    isFixed: true,
    isVisible: !isIntersecting,
    transparent: false
  }, React.createElement(svs.ui.ReactBottomSheet.BottomSheetFooter, null, children));
};
setGlobal('svs.components.lbUi.bottomSheetIntersect.BottomSheetIntersersect', BottomSheetIntersersect);

 })(window);